import React, { FunctionComponent } from "react"
import { graphql, PageProps } from "gatsby"
import * as SCM from "../types"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Press from "../components/Press"

type Props = PageProps & {
  data: {
    pressPage: SCM.PressPage
  }
}

const PressTemplate: FunctionComponent<Props> = props => {
  const pressPage = props.data.pressPage

  return (
    <Layout>
      <SEO
        locale={pressPage.locale}
        uri={props.uri}
        breadcrumb={[
          {
            position: 1,
            name: "Press",
            slug: "/press",
          },
        ]}
        {...pressPage.seo}
      />
      <Press {...pressPage} />
    </Layout>
  )
}

export default PressTemplate

export const query = graphql`
  query PressPageQuery {
    pressPage {
      ...pressPageFragment
    }
  }
`
